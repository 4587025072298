<template>
  <div class="maillist">
    <div class="maillist-left">
      <div class="maillist-header">
        <div
          class="all"
          :class="followtext == '全部' ? 'active-affected' : null"
          @click="changefollowtext('全部')"
        >
          全部
        </div>
        <div
          class="Most-affected"
          :class="followtext == '最受关注' ? 'active-affected' : null"
          @click="changefollowtext('最受关注')"
        >
          最受关注
        </div>
        <div class="search-box">
          <input
            type="text"
            v-model="queryParams.text"
            placeholder="输入你想查找的用户"
            @change="getexpertlist()"
          />
          <i class="iconfont icon-fangdajing"></i>
        </div>
      </div>
      <div class="message-totle">共{{ expertCount }}条</div>
      <div class="user-list-box">
        <div class="" v-for="item in expertlist" @click="goexpertDetails(item)">
          <div class="user-list-items">
            <div class="header-img">
              <img :src="item.photo" />
            </div>
            <div class="message">
               <div>
                {{ item.name }}
              </div>
              
              <div>
             
                <span class="message_post">{{ item.post }}</span> |
                <span class="message_company">{{ item.company }}</span>
              </div>
            </div>
             <div class="textarea-date-box">
              <div class="Operationtype">
                <!-- <div @click.stop="clickExpertLike(item.id)">
                  <img :src="imgIcon.imgZan" />
                  <span class="count">{{ item.like }}</span>
                </div> -->
                <!-- <div @click.stop="clickExpertFav(item.id)">
                    <img v-if="item.isFav" :src="imgIcon.imgCangTrue" />
                  <img  v-else :src="imgIcon.imgCang" />
               
                </div> -->
                <div @click="clickExpertFav(item.id)"  v-if="item.isFav">
             <img :src="imgIcon.imgCangTrue" />
                    <span>已收藏</span>
             
            </div>
            <div @click.stop="clickExpertFav(item.id)" v-else>
                    <img :src="imgIcon.imgCang" />
                    <span class="count">收藏</span>
                  </div>
              </div>
            </div> 
          </div>
        </div>
      </div>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :page-size="10"
        layout="prev, pager, next, jumper"
        :total="expertCount"
      >
      </el-pagination>
    </div>
    <div class="maillist-right">
      <!-- <div class="hot-demand">
        <div class="hot-demand-header">
          <div>可能感兴趣的</div>
        </div>
        <div class="hot-demand-item" v-for="(item, index) in 5">
          <div class="item-left">
            <img src="@/assets/images/touxiang.png" alt="" />
          </div>
          <div class="item-mid">
            <p>天福</p>
            <p>浙江帮里投资有限公司-销售</p>
          </div>
        
        </div>
      </div> -->
      <div class="group-box">
        <div class="group-header">
          <p class="header-title">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-a-zu337"></use>
            </svg>
            圈子
          </p>
          <!-- <div class="see-more">查看更多</div> -->
        </div>
        <div class="group-item">
          <div class="group-items" v-for="item in grouplist" @click="goCircleDetails(item)">
            <i class="iconfont icon-a-zu338"></i>
            <svg class="icon icon-a-zu337" aria-hidden="true">
              <use xlink:href="#icon-a-zu337"></use>
            </svg>
            <div class="title">
              <p>{{ item.title }}</p>
            </div>
            <!-- <div class="join"  @click="$store.commit('setVideoDialog', { show: true, url: item.linkUrl,title: item.title })"
            > -->
            <div
              class="join"
             
            >
              <i class="iconfont icon-a-zu340"></i>
              加入
            </div>
          </div>
        </div>
      </div>
      <div class="join-Groupchat">
        <div>加入供需社群</div>
        <div class="ewmcode-box">
          <div class="ewmcode">
            <!-- lxrewm == 供需群二维码 -->
            <div class="gxqewm">
              <img src="@/assets/images/wxmpj.jpg" alt="" />
            </div>
            <img src="@/assets/images/ewmicon.png" alt="" />
            <br /><br />
            供需群
          </div>
          <div class="ewmcode">
            <!-- lxrewm == 联系人二维码 -->
            <div class="lxrewm">
              <img src="@/assets/images/wxewm.png" alt="" />
            </div>
            <img src="@/assets/images/ewmicon.png" alt="" />
            <br /><br />
            联系人
          </div>
        </div>
      </div>
    </div>
    <video-dialog />
  </div>
</template>

<script>
import {
  Grouplist,
  expertslist,
  expertLike,
  expertLikeDown,
  expertFav,
  expertFavDown,
  expertFavList
} from "@/api";
import { imgObj } from "@/utils/const.js";
export default {
  name: "maillist",
  components: {
    videoDialog: () => import("@/components/Circle"),
  },
  data() {
    return {
      currentPage: 1,

      queryParams: {
        //列表搜索参数
        pageIndex: 1,
        pageSize: 10,
        ids: [],
        name: "",
        text: "",
      },
      queryMyParams:{
        pageIndex: 1,
        pageSize: 10,
        userId: localStorage.getItem("userId") - 0,
      },
      followtext: "全部",

      grouplist: [],
      expertCount: 0,
      expertlist: [],
      expertMyList:[],
      imgIcon: imgObj,
    };
  },
  methods: {
    start(){
      this.getMyExpertList()
      expertslist(this.queryParams).then((res) => {
        console.log("专家", res);
        this.expertlist = res.data.data.item1;
        this.expertCount = res.data.data.item2;
        const ids = this.expertMyList.map((i) => i.id);
        console.log(ids);
        this.expertlist.forEach((aitem) => {
          if (ids.includes(aitem.id)) {
            aitem.isFav = true;
          } else {
            aitem.isFav = false;
          }
          console.log(aitem)
        });
  
      });
    },
      goCircleDetails(item) {
      localStorage.setItem("secondpath", JSON.stringify(this.$route.path));
      let routeData = this.$router.resolve({
        name: "circleDetailsPage",
        query: {
          id: item.id|| item.Id,
          source: Number(item.source),
          title: item.title || item.Title,
          linkUrl:item.linkUrl 
        },
      });
      window.open(routeData.href, "_blank");
    },
    getexpertlist() {
      expertslist(this.queryParams).then((res) => {
        console.log("专家", res);
        this.expertlist = res.data.data.item1;
        this.expertCount = res.data.data.item2;
      });
    },
     getMyExpertList() {
      expertFavList(this.queryMyParams).then((res) => {
        console.log("专家", res);
        this.expertMyList = res.data.data.item1;

      });
    },
    async getgrouplist() {
      Grouplist().then((res) => {
        console.log("圈子标题", res);
        this.grouplist = res.data;
      });
    },
    goexpertDetails(item) {
      console.log(item);
      localStorage.setItem("secondpath", JSON.stringify(this.$route.path));
      // this.$router.push(`/DetailsPage?id=${item.id}&type=${type}`)
      let routeData = this.$router.resolve({
        name: "expertDetails",
        query: {
          id: item.id,
          title:item.name +"|"+item.company+"|"+item.post
        },
      });
      window.open(routeData.href, "_blank");
    },

    // joinCircle(urlLink){
    //   $store.commit('setVideoDialog', { show: true, url: urlLink })

    // },
    clickExpertLike(expertId) {
      let username = window.localStorage.getItem("userName");
      console.log(username);
      if (username) {
        let data = {
          userId: localStorage.getItem("userId") - 0,
          expertId: expertId,
        };
        expertLike(data).then((res) => {
          console.log(res);
          if (res.data.code == "400") {
            expertLikeDown(data).then((res) => {
              this.getexpertlist();
            });
          } else {
            this.getexpertlist();
          }
        });
      } else {
        // this.$router.push('/login')
        this.$message({
          message: "请先登陆才能点赞",
          type: "warning",
        });
      }
      // let data = {
      //   userId: 1,
      //   expertId: expertId,
      // };
      // expertLike(data).then((res) => {
      //   console.log(res);
      //   this.start();
      // });
    },
    clickExpertFav(expertId) {
      let username = window.localStorage.getItem("userName");
      console.log(username);
      if (username) {
        let data = {
          userId: localStorage.getItem("userId") - 0,
          expertId: expertId,
        };
        expertFav(data).then((res) => {
          console.log(res);
          if (res.data.code == "400") {
            expertFavDown(data).then((res) => {
              this.start();
            });
          } else {
            this.start();
          }
        });
      } else {
        this.$router.push('/login')
        this.$message({
          message: "您还没有登录，请先登陆才能收藏该文章",
          type: "warning",
        });
      }
      // let data = {
      //   userId: 1,
      //   expertId: expertId,
      // };

      // expertFav(data).then((res) => {
      //   console.log(res);
      //   this.start();
      // });

      // else{
      //   expertFavDown(data).then((res) =>{
      //   console.log(res
      //   )
      //   this.start();
      //   })
      // }
    },
    changefollowtext(name) {
      this.followtext = name;
    },
    handleCurrentChange(e) {
      console.log(e);
      this.currentPage = e;
      this.queryParams = {
        pageIndex: this.currentPage,
        pageSize: 10,
        ids: [],
        name: "",
        text: "",
      };
      expertslist(this.queryParams).then((res) => {
        console.log("专家", res);
        this.expertlist = res.data.data.item1;
        this.expertCount = res.data.data.item2;
      });
    },
    handleSizeChange(e) {
      this.currentPage = e;
      this.queryParams = {
        pageIndex: this.currentPage,
        pageSize: 10,
        ids: [],
        name: "",
        text: "",
      };
      expertslist(this.queryParams).then((res) => {
        console.log("专家", res);
        this.expertlist = res.data.data.item1;
        this.expertCount = res.data.data.item2;
      });
    },
  },
  created() {
    this.getgrouplist();
    // this.getexpertlist();
    this.start()
  },
};
</script>

<style lang="less" scoped>
.maillist {
  width: 1280px;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 50px;
  display: flex;
  .maillist-left {
    width: 100%;
    margin-right: 20px;
    align-items: center;
    .maillist-header {
      padding: 20px;
      background: white;
      display: flex;
      position: relative;
      .search-box {
        position: absolute;
        right: 20px;
        // top: px;
        input {
          outline: none;
          background: #f5f5f5;
          border: 1px solid #f5f5f5;
          padding: 7px 30px 7px 20px;
          font-size: 14px;
          border-radius: 17px;
        }
        .iconfont {
          position: absolute;
          color: #333333;
          right: 15px;
          top: 6px;
          cursor: pointer;
        }
      }
      .Most-affected {
        margin-left: 20px;
        padding: 8px 20px;
        font-size: 15px;
        text-align: center;
        cursor: pointer;
      }
      .all {
        font-size: 14px;
        padding: 8px 15px;
        text-align: center;
        cursor: pointer;
      }
      .active-affected {
        background: #0966a9;
        color: #fff;
        // font-weight: bold;
        border-radius: 3px;
      }
    }
    .message-totle {
      padding: 20px;
      font-size: 14px;
      color: #989898;
      text-align: right;
    }
    .user-list-box {
      
      background: white;
      .user-list-items {
        padding: 20px 20px;
        display: flex;
        align-items: center;
        border-bottom: 0.0625rem solid #e9e9e9;
        .header-img {
          width: 100px;
          margin-right: 50px;
          margin-left: 13px;
          img {
            width: 80px;
            height: 80px;
            border-radius: 50%;
          }
        }
        .message {
          font-size: 15px;
          width: 100%;
          
          div:nth-child(1) {
            font-size: 20px;
            font-weight: bold;
            span {
              padding: 5px 16px;
              font-size: 14px;
              color: #6a6a6a;
              margin-left: 20px;
              background: #f5f6f6;
            }
          }
          div:nth-child(2) {
            margin-top: 15px;
            font-size: 14px;
            color: #9a999e;
          }
          .message_post {
            color: #111111;
            font-weight: 600;
            margin-right: 10px;
          }
          .message_company {
            margin-left: 10px;
          }
        }
        .textarea-date-box {
          display: flex;
          justify-content: space-between;
          margin-top: -60px;
          span:nth-child(2) {
            font-size: 14px;
            color: #999;
          }
          .Operationtype {
            display: flex;
            font-size: 14px;
            color: #999;
            cursor: pointer;
            div {
              
              background: #fff;
              color: #525252;
              font-size: 14px;
              margin-right: 20px;
              border: 0.0625rem solid #ededed;
            padding: 0.6rem 1.2rem;
              display: flex;
              align-items: flex-end;
              span {
                margin-left: 10px;
                width: 50px;
              }
            }
            // div:nth-child(1) {
            //   padding: 10px 20px;
            //   background: #f5f6f6;
            //   color: #0966a9;
            //   font-size: 14px;
            // }
            // div {
            //   padding: 10px;
            //   margin-right: 20px;
            // }
            .count {
              margin-left: 10px;
            }
          }
        }
        .friends {
          width: 100px;
          color: white;
          background: #0966a9;
          font-size: 16px;
          padding: 10px 31px;
          border-radius: 29px;
          text-align: center;
          cursor: pointer;
          .iconfont {
            margin-right: 10px;
          }
        }
      }
     :hover{
          cursor: pointer;
    background: #fafafa
      }
    }
  }
  .maillist-right {
    width: 40%;
    // background: sandybrown;
    .hot-demand {
      background: white;
      .hot-demand-header {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        font-weight: 400;
        padding: 20px;
        font-size: 20px;
        border-bottom: 1px solid #e9e9e9;
        :nth-child(2) {
          font-size: 16px;
          font-weight: normal;
          color: #9a9a9a;
        }
      }
      .hot-demand-item {
        &:nth-child(odd) {
          background: #e9e9e950;
        }
        padding: 20px;
        background: white;
        display: flex;
        align-items: center;
        .item-left {
          width: 100px;
          img {
            width: 100%;
            border-radius: 50%;
          }
        }
        .item-mid {
          width: 100%;
          margin: 0 10px;
          p {
            margin: 0;
            margin-bottom: 10px;
            font-size: 12px;
          }
          p:nth-child(1) {
            font-size: 14px;
            font-weight: bold;
          }
          .sub-box {
            display: flex;
            div {
              margin-right: 10px;
              font-size: 12px;
              color: #0966a9;
              padding: 4px 5px;
              border: 1px solid #0966a9;
              cursor: pointer;
            }
          }
        }
        .item-right {
          width: 120px;
          background: white;
          text-align: center;
          padding: 6px 0px;
          font-size: 13px;
          color: #616161;
          cursor: pointer;
          border: 1px solid #616161b2;
          .iconfont {
            font-weight: bold;
          }
        }
      }
    }
    .join-Groupchat {
      margin-top: 20px;
      background: white;
      div:nth-child(1) {
        padding: 20px;
      }
      .ewmcode-box {
        padding: 20px;
        display: flex;
        justify-content: space-between;
        .ewmcode {
          width: 100%;
          padding: 20px;
          text-align: center;
          cursor: pointer;
          position: relative;
          top: -20px;
          &:nth-child(2) {
            &:hover {
              .lxrewm {
                display: block;
                img {
                  width: 100%;
                }
              }
            }
          }
          &:nth-child(1) {
            &:hover {
              .gxqewm {
                display: block;
                img {
                  width: 100%;
                }
              }
            }
          }
          .gxqewm,
          .lxrewm {
            width: 120px;
            height: 120px;
            position: absolute;
            background: white;
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.322);
            top: -150px;
            display: none;
            right: -58px;
          }
        }
        .ewmcode:nth-child(1) {
          border-right: 1px solid #e9e9e9;
        }
      }
    }
    .group-box {
      background: white;
      // margin-top: 20px;
      .group-header {
        padding-top: 16px;
        display: flex;
        justify-content: space-between;
        .see-more {
          line-height: 25px;
          padding-right: 20px;
          color: #9a9a9a;
        }
        border-bottom: 1px solid #e9e9e9;
        .header-title {
          font-size: 16px;
          color: #0b1d47;
          margin-top: 0;
          margin-left: 20px;
          margin-bottom: 20px;
          .icon {
            width: 18px;
            height: 18px;
            margin-right: 15px;
            position: relative;
            top: 5px;
          }
          span {
            font-size: 18px;
            font-weight: normal;
            color: #707070;
          }
        }
        .visiting-card {
          height: 20px;
          line-height: 20px;
          padding: 5px 15px;
          margin-top: 0;
          font-size: 14px;
          border-radius: 15px;
          margin-left: 4%;
          cursor: pointer;
        }
        .visiting-card-active {
          background: #b7d9ef65;
        }
        .wechat-group {
          height: 20px;
          line-height: 20px;
          font-size: 14px;
          padding: 5px 15px;
          border-radius: 15px;
          margin-left: 15px;
          cursor: pointer;
        }
        .wechat-group-active {
          background: linear-gradient(231deg, #2fb6b649 0%, #128f9325 100%);
        }
      }
      .group-item {
        width: 100%;
        .group-items {
          cursor: pointer;
          padding: 20px 20px;
          display: flex;
          position: relative;
          align-items: flex-end;
          .icon-a-zu337 {
            width: 20px;
            height: 20px;
            display: none;
            position: relative;
            top: 0px;
          }
          &:hover {
            padding: 20px 20px;
            background: #fafafa;
            .join {
              // background: #31448342;
              .iconfont {
                color: #0966a9;
              }
            }
            .title {
              color: #314483;
              
              // font-weight: bold;
              cursor: pointer;
            }
            // .icon-a-zu338 {
            //   display: none;
            // }
            // .icon-a-zu337 {
            //   display: block;
            // }
          }
          i {
            font-size: 20px;
          }
          .title {
            margin-left: 20px;
            margin-top: 2px;
            p {
              width: 210px;
              margin: 0;
              line-height: 22px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
          .join {
            padding: 5px 15px;
            position: absolute;
            right: 10px;
            font-size: 14px;
            text-align: center;
            .iconfont {
              color: #cbcbcb;
              font-size: 16px;
            }
          }
        }
      }
    }
  }
  .el-pagination {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    /deep/ li {
      margin: 0 8px !important;
      min-width: 36px !important;
      height: 34px;
      line-height: 36px;
    }
    /deep/ .active {
      color: white;
      background: #0966a9;
    }
    /deep/ li:hover {
      color: white;
      background: #0966a9;
    }
    /deep/ button {
      margin: 0 8px !important;
      height: 34px;
      line-height: 36px;
    }
    /deep/ .btn-next {
      padding: 0;
    }
    /deep/ .btn-prev {
      padding: 0;
    }
    /deep/ button:hover {
      color: white;
      background: #0966a9;
    }
    /deep/ span {
      margin-right: 30px;
      line-height: 36px;
    }
  }
}
</style>